import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import emailImage from '../../assets/images/dashboard/mail.svg';
import spareParsImage from '../../assets/images/dashboard/spare-parts.svg';
import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';
import useOutsideClick from 'hooks/useOutsideClick';
import VendorCode from 'components/VendorCode/VendorCode';
import { useWriteMessage } from 'hooks/api/useOtherRequests';
import useToastNotification from 'hooks/useToastNotification';

const initialValues = {
  theme: '',
  message: '',
};

const formTypes = {
  isManager: 'manager',
  isSalesman: 'salesman',
  isManagerSpareParts: 'selection_manager',
};

const vinRegex = /^[A-Za-z0-9]{17}$/;

const ManagerForm = memo((props) => {
  const { onFlipBack, type, email } = props;

  const formRef = useRef();

  const { t } = useTranslation();

  const { notify, notifyStatus } = useToastNotification();

  const { mutate: sendMessage, isSuccess, isLoading, isError } = useWriteMessage();

  const onSubmit = useCallback(
    (event) => {
      sendMessage({
        email,
        title: event.theme,
        message: event.message,
        type,
      });
    },
    [email, sendMessage]
  );

  useEffect(() => {
    if (isLoading) setTimeout(() => onFlipBack(false), 500);
  }, [isLoading, onFlipBack]);

  useEffect(() => {
    if (isSuccess) {
      formRef.current.reset();
      notify(t('info.message_sent_success'), notifyStatus.success);
    }
    if (isError) {
      notify(t('info.message_sent_error'), notifyStatus.error);
    }
  }, [isSuccess, isError, notifyStatus, t, notify]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.theme) errors.theme = t('errors.required_field');
        if (!values.message) errors.message = t('errors.required_field');
        return errors;
      }}
      render={({ handleSubmit, values, form }) => {
        formRef.current = form;

        return (
          <div className="message-block back">
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Field name="theme">
                {({ input, meta }) => (
                  <div className="field-wrap" style={{ position: 'relative' }}>
                    <input {...input} type="text" placeholder={t('label.theme')} />
                    {meta.error && meta.touched && (
                      <span className="form-field_error" style={{ bottom: -20 }}>
                        {meta.error}
                      </span>
                    )}
                  </div>
                )}
              </Field>

              <Field name="message">
                {({ input, meta }) => (
                  <>
                    <div className="field-wrap" style={{ height: 220, position: 'relative' }}>
                      <textarea {...input} placeholder={t('label.message_placeholder_label')} style={{ height: '100%' }} />
                      {meta.error && meta.touched && (
                        <span className="form-field_error" style={{ bottom: 0 }}>
                          {meta.error}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </Field>
              <div className="message-block__btn-wrap">
                <button type="submit" className="btn btn-flip-back">
                  {isLoading ? t('label.sent') : t('action.write_to_your_sales_manager')}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    />
  );
});

const SelectionManagerForm = memo((props) => {
  const { onFlipBack, type, email } = props;

  const formRef = useRef();

  const { t } = useTranslation();

  const { notify, notifyStatus } = useToastNotification();

  const { mutate: sendMessage, isSuccess, isLoading, isError } = useWriteMessage();

  const onSubmit = useCallback(
    (event) => {
      sendMessage({
        email,
        title: event.theme,
        message: event.message,
        vin_code: event.vin_code ?? '',
        type,
      });
    },
    [email, sendMessage]
  );

  useEffect(() => {
    if (isLoading) setTimeout(() => onFlipBack(false), 500);
  }, [isLoading, onFlipBack]);

  useEffect(() => {
    if (isSuccess) {
      formRef.current.reset();
      notify(t('info.message_sent_success'), notifyStatus.success);
    }
    if (isError) {
      notify(t('info.message_sent_error'), notifyStatus.error);
    }
  }, [isSuccess, isError, notifyStatus, t, notify]);

  return (
    <Form
      initialValues={{ ...initialValues, vin_code: '' }}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.theme) errors.theme = t('errors.required_field');
        if (!values.message) errors.message = t('errors.required_field');
        if (values.vin_code && !vinRegex.test(values.vin_code)) errors.vin_code = t('errors.wrong_value');
        return errors;
      }}
      render={({ handleSubmit, values, form }) => {
        formRef.current = form;

        return (
          <div className="message-block back">
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Field name="theme">
                {({ input, meta }) => (
                  <div className="field-wrap" style={{ position: 'relative' }}>
                    <input {...input} type="text" placeholder={t('label.theme')} />
                    {meta.error && meta.touched && (
                      <span className="form-field_error" style={{ bottom: -20 }}>
                        {meta.error}
                      </span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="vin_code">
                {({ input, meta }) => (
                  <div className="field-wrap" style={{ position: 'relative' }}>
                    <input {...input} type="text" placeholder={t('label.VIN_number')} />
                    {meta.error && meta.touched && (
                      <span className="form-field_error" style={{ bottom: -20 }}>
                        {meta.error}
                      </span>
                    )}
                  </div>
                )}
              </Field>

              <Field name="message">
                {({ input, meta }) => (
                  <div className="field-wrap" style={{ height: 140, position: 'relative' }}>
                    <textarea {...input} placeholder={t('label.message_placeholder_label')} style={{ height: '100%' }} />
                    {meta.error && meta.touched && (
                      <span className="form-field_error" style={{ bottom: 0 }}>
                        {meta.error}
                      </span>
                    )}
                  </div>
                )}
              </Field>
              <div className="message-block__btn-wrap">
                <button type="submit" className="btn btn-flip-back">
                  {isLoading ? t('label.sent') : t('action.write_to_your_sales_manager')}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    />
  );
});

const ManagerCard = memo((props) => {
  const { isManager, isSalesman, isManagerSpareParts, managerData } = props;

  const [isFlip, setIsFlip] = useState(false);

  const { t } = useTranslation();

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isFlip) setIsFlip(false);
  });

  const lastname = managerData?.lastname;
  const firstname = managerData?.firstname;
  const phoneNumber = managerData?.phone;
  const email = managerData?.email;
  const photo = imgUrlToResizeUrl(managerData?.image, 312, 281, 'resize');

  switch (true) {
    case isManager:
      return (
        <div ref={ref} className={`info-blocks__item flip-block ${isFlip ? 'flip' : ''}`}>
          <div className="manager dark front">
            <div className="manager__title title">
              <h3>
                {t('label.your')}
                <br /> {t('label.manager')}
              </h3>
            </div>
            <div className="manager__img-wrap">
              <img src={emailImage} width="160" height="192" alt="" />
            </div>
            <div className="manager-data">
              <p className="manager-data__full-name">
                {lastname}
                <br />
                {firstname}
              </p>

              <strong className="manager-data__phone">{phoneNumber}</strong>

              <div className="manager__bottom">
                <VendorCode mailtoLink={`mailto:${email}`} article={email} />
                <button type="button" className="btn btn_bg-col-white btn-flip" onClick={() => setIsFlip(true)}>
                  {t('action.write_to_your_sales_manager')}
                </button>
              </div>
            </div>
          </div>

          <ManagerForm email={email} type={formTypes.isManager} onFlipBack={setIsFlip} />
        </div>
      );
    case isManagerSpareParts:
      return (
        <div ref={ref} className={`info-blocks__item flip-block ${isFlip ? 'flip' : ''}`}>
          <div className="manager front">
            <div className="manager__title title">
              <h3>
                {t('label.manager_cl')}
                <br />
                {t('label.from_selection_of_spare_parts')}
              </h3>
            </div>
            <div className="manager__img-wrap">
              <img src={spareParsImage} width="160" height="240" alt="" />
            </div>
            <div className="manager-data">
              <p className="manager-data__full-name">
                {lastname}
                <br />
                {firstname}
              </p>

              <strong className="manager-data__phone">{phoneNumber}</strong>

              <div className="manager__bottom">
                <VendorCode mailtoLink={`mailto:${email}`} article={email} />
                <button type="button" className="btn btn-flip" onClick={() => setIsFlip(true)}>
                  {t('action.write_to_your_sales_manager')}
                </button>
              </div>
            </div>
          </div>

          <SelectionManagerForm email={email} type={formTypes.isManagerSpareParts} onFlipBack={setIsFlip} />
        </div>
      );
    case isSalesman:
    default:
      return (
        <div ref={ref} className={`info-blocks__item flip-block ${isFlip ? 'flip' : ''}`}>
          <div className="trading-agent front">
            <div className="trading-agent__title title">
              <h3>
                {t('label.your')} <br /> {t('label.sales')}
              </h3>
            </div>
            <div className="trading-agent__photo-wrap">
              <img src={photo} width="180" height="180" alt="" />
            </div>
            <div className="trading-agent-data">
              <p className="trading-agent-data__full-name">
                {lastname} <br />
                {firstname}
              </p>

              <strong className="trading-agent-data__phone">{phoneNumber}</strong>

              <div className="trading-agent__bottom">
                <VendorCode mailtoLink={`mailto:${email}`} article={email} />
                <button type="button" className="btn btn-flip" onClick={() => setIsFlip(true)}>
                  {t('action.write_to_your_sales_manager')}
                </button>
              </div>
            </div>
          </div>

          <ManagerForm email={email} type={formTypes.isSalesman} onFlipBack={setIsFlip} />
        </div>
      );
  }
});

export default ManagerCard;
