import { useQuery } from 'react-query';
import { useApi } from 'services/api';
import { profileKeys } from 'services/queryClient/queryKeys';
import logger from 'services/logger';

export const useGetProfile = (params = {}) => {
  const { user, setContentLanguage } = useApi();

  return useQuery(profileKeys.user(), () => user.getUser(), {
    select: (res) => {
      res.data.profile.debt.debt = res.data.profile.debt.debt > 0 ? res.data.profile.debt.debt : 0;
      return res.data.profile;
    },
    onError: (error) => logger(error),
    onSuccess: (res) => setContentLanguage(res.settings.locale),
    keepPreviousData: true,
    ...params,
  });
};

export const useGetManagers = () => {
  const { user } = useApi();

  return useQuery(profileKeys.managers(), () => user.getManagers(), {
    select: (res) => ({
      manager: res?.data?.manager,
      salesman: res?.data?.salesman,
      selection_manager: res?.data?.selection_manager,
    }),
    onError: (error) => logger(error),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
